import React, { createContext, useContext, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { app, device } from "src/utils/capacitor"
import dataService from "src/services/data-service"

const AppState = createContext()

const AppStateProvider = props => {
  const { children } = props
  const staticData = useStaticQuery(query)
  const [data, setData] = useState({
    branches: staticData.allBranchesYaml.nodes,
    settings: staticData.dataYaml,
    isFetching: true,
  })
  const updateData = () => {
    dataService.fetchDataWithCache(
      cachedOrNewData => {
        setData({
          settings: cachedOrNewData.dataYaml,
          branches: cachedOrNewData.allBranchesYaml.nodes,
          isFetching: false,
        })
      },
      error => {
        console.error("error", error)
        setData({
          ...data,
          isFetching: false,
        })
      }
    )
  }
  device.useEffectOnBrowser(() => {
    setData({ ...data, isFetching: false })
  }, [])
  device.useEffectOnNative(() => {
    updateData()
  }, [])
  device.useEffectOnNative(() => {
    return app.onActivate(updateData)
  }, [])
  device.useEffectOnNative(() => {
    return app.onOnline(updateData)
  }, [])
  return <AppState.Provider value={data}>{children}</AppState.Provider>
}

const useAppState = () => {
  return useContext(AppState)
}

const query = graphql`
  query AppData {
    allBranchesYaml(
      filter: { slug: { ne: "dummy" } }
      sort: { fields: order }
    ) {
      nodes {
        title
        slug
        sectionIntro {
          title
          subtitle
        }
        sectionFoodMenu {
          subtitle
          title
        }
        sectionAboutUs {
          title
          subtitle
          features {
            content
            icon
            title
          }
        }
        sectionNews {
          title
          news {
            text
          }
        }
        sectionContacts {
          title
          subtitle
          contact {
            content
            icon
            title
          }
        }
        urlSettings {
          mealsUrl
          offersUrl
          currencyUrl
          offersSchedulingUrl
          offersSchedulingConfUrl
          mapUrl
        }
      }
    }
    dataYaml {
      title
      description
      noMenuMeals
      errorMenuMeals
      offlineMenuMeals
      cookiesConsent
      googlePlayStoreUrl
      appleAppStoreUrl
    }
  }
`

export { AppStateProvider, useAppState }

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { navigate } from "gatsby"

import { AppStateProvider } from "src/state/app-state"
import { device, cache } from "src/utils/capacitor"

const PageWrapper = props => {
  const { children, location } = props
  const currentLocation = location.pathname + location.hash
  device.useEffectOnNative(() => {
    cache.getSafely("lastLocation").then(lastLocation => {
      if (lastLocation && currentLocation !== lastLocation) {
        navigate(lastLocation)
      }
    })
  }, [])
  device.useEffectOnNative(() => {
    cache.setSafely("lastLocation", currentLocation)
  }, [currentLocation])
  return children
}

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>{element}</PageWrapper>
}

export const wrapRootElement = ({ element }) => {
  return <AppStateProvider>{element}</AppStateProvider>
}

import { useEffect } from "react"
import { Capacitor, Plugins } from "@capacitor/core"

const { Storage, App, Network } = Plugins

const device = {
  isNative() {
    if (isSsrPhaseForMobile()) {
      return true
    }
    return !!Capacitor.isNative
  },
  useEffectOnNative(fn, deps) {
    useEffect(() => {
      if (device.isNative()) {
        return fn()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
  },
  useEffectOnBrowser(fn, deps) {
    useEffect(() => {
      if (!device.isNative()) {
        return fn()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
  },
}

const cache = {
  get(key) {
    return Storage.get({ key }).then(({ value }) => JSON.parse(value))
  },
  set(key, value) {
    return Storage.set({ key: key, value: JSON.stringify(value) })
  },
  getSafely(key) {
    return cache.get(key).catch(error => {
      console.error("Error in cache.get", error)
    })
  },
  setSafely(key, value) {
    return cache.set(key, value).catch(error => {
      console.error("Error in cache.set", error)
    })
  },
  getWithRevalidate(key, revalidateFn, dataCallback, errorCallback) {
    Promise.resolve()
      .then(() => {
        return cache.getSafely(key)
      })
      .then(cachedData => {
        if (cachedData) {
          dataCallback(cachedData)
        }
        return Promise.all([cachedData, revalidateFn()])
      })
      .then(([cachedData, networkData]) => {
        if (cachedData && equals(cachedData, networkData)) {
          return
        }
        dataCallback(networkData)
        cache.setSafely(key, networkData)
      })
      .catch(error => {
        console.error("Error in revalidate", key, error)
        errorCallback(error)
      })
  },
}

const app = {
  onActivate(callback) {
    const handler = App.addListener("appStateChange", ({ isActive }) => {
      if (isActive) {
        callback()
      }
    })
    return handler.remove
  },
  onOnline(callback) {
    const handler = Network.addListener(
      "networkStatusChange",
      ({ connected }) => {
        if (connected) {
          callback()
        }
      }
    )
    return handler.remove
  },
}

const equals = (obj1, obj2) => {
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  } catch (error) {
    console.info("Compare objects failed", obj1, obj2, error)
    return false
  }
}

const isSsrPhaseForMobile = () =>
  process.env.GATSBY_TARGET_ENV === "native" && typeof window === "undefined"

export { device, cache, app }

const httpClient = {
  fetchJson(url) {
    return fetch(url)
      .catch(error => {
        console.error(HTTP_ERRORS.NETWORK_ERROR, error)
        throw new Error(HTTP_ERRORS.NETWORK_ERROR)
      })
      .then(response => {
        if (!response.ok) {
          console.error(HTTP_ERRORS.RESPONSE_ERROR, response)
          throw new Error(HTTP_ERRORS.RESPONSE_ERROR)
        }
        return response.json()
      })
  },
}

const HTTP_ERRORS = {
  RESPONSE_ERROR: "RESPONSE_ERROR",
  NETWORK_ERROR: "NETWORK_ERROR",
}

export default httpClient
export { HTTP_ERRORS }

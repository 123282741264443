import httpClient from "src/utils/http-client"
import { cache } from "src/utils/capacitor"

const url = "https://dobra-basta.sk/page-data/sq-data.json"
// const url = "http://192.168.1.25:8080/page-data/sq-data.json"

const dataService = {
  fetchData() {
    return httpClient.fetchJson(url).then(response => response?.data)
  },
  fetchDataWithCache(dataCallback, errorCallback) {
    return cache.getWithRevalidate(
      "data",
      dataService.fetchData,
      dataCallback,
      errorCallback
    )
  },
}

export default dataService
